










































































import BaseToolbar from '@/components/core/BaseToolbar.vue';
import MyStrategiesMixin from '@/mixins/mystrategies-mixin';
import { Component } from 'vue-property-decorator';

  @Component({
    mixins: [MyStrategiesMixin],
    components: {
      BaseToolbar
    }
  })
export default class MyStrategiesToolbar extends BaseToolbar {
    editMode!: boolean;
    searchText!: string;
    selectedRows!: Array<any>;
    hasSelectedRows!: boolean;

    get localMyStrategiesComponent(): any {
      return this.currentPageInstance || {};
    }

    get toggleEditMode() {
      return this.localMyStrategiesComponent.toggleEditMode || this.emptyFunction;
    }

    get addNewStrategy() {
      return this.localMyStrategiesComponent.addNewStrategy || this.emptyFunction;
    }

    get deleteStrategies() {
      return this.localMyStrategiesComponent.deleteStrategies || this.emptyFunction;
    }

    get localAdminSelectedTab() {
      return this.localMyStrategiesComponent.localAdminSelectedTab;
    }

    set localAdminSelectedTab(value: any) {
      this.localMyStrategiesComponent.localAdminSelectedTab = value;
    }
}


import BaseToolbar from '@/components/core/BaseToolbar.vue';
import MessagesMixin from '@/mixins/messages-mixin';
import { Component } from 'vue-property-decorator';
import PrintOptions from '../prints/PrintOptions.vue';

@Component({
  mixins: [MessagesMixin],
  components: {
    BaseToolbar,
    PrintOptions
  }
})
export default class MessagesToolbar extends BaseToolbar {
  editMode!: boolean;
  searchText!: string;
  selectedRows!: Array<any>;
  hasSelectedRows!: boolean;

  $refs!: {
    printOptions: PrintOptions,
  };

  get localMessagesComponent() {
    return this.currentPageInstance || {};
  }

  get toggleEditMode() {
    return this.localMessagesComponent.toggleEditMode || this.emptyFunction;
  }

  get addMessage() {
    return this.localMessagesComponent.addMessage || this.emptyFunction;
  }

  get deleteMultipleSelectedMessage() {
    return this.localMessagesComponent.deleteMultipleSelectedMessage || this.emptyFunction;
  }
}

import CommonUtils from '@/utils/common-utils';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class MyStrategiesMixin extends Vue {
  get searchText() {
    return this.$store.state.mystrategies.searchText || '';
  }

  set searchText(value: string) {
    this.$store.commit('mystrategies/setSearchText', value);
  }

  get selectedRows() {
    return this.$store.state.mystrategies.selectedRows || [];
  }

  set selectedRows(value: Array<any>) {
    this.$store.commit('mystrategies/setSelectedRows', value);
  }

  get hasSelectedRows() {
    return CommonUtils.isNotEmpty(this.selectedRows);
  }

  get editMode() {
    return this.$store.state.mystrategies.editMode;
  }

  set editMode(value: boolean) {
    this.$store.commit('mystrategies/setEditMode', value);
  }

  created() {
    this.editMode = false;
    this.searchText = '';
    this.selectedRows = [];
  }
}

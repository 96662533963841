import { render, staticRenderFns } from "./MessagesToolbar.vue?vue&type=template&id=5afa5088"
import script from "./MessagesToolbar.vue?vue&type=script&lang=ts"
export * from "./MessagesToolbar.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCol,VIcon,VList,VListItem,VMenu,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/Users/adriandaluz/Development/git/planbook-web-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5afa5088')) {
      api.createRecord('5afa5088', component.options)
    } else {
      api.reload('5afa5088', component.options)
    }
    module.hot.accept("./MessagesToolbar.vue?vue&type=template&id=5afa5088", function () {
      api.rerender('5afa5088', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/messages/MessagesToolbar.vue"
export default component.exports